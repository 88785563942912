// Generated by Framer (69c895f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, SVG, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["EvriYDBXZ", "hNcas0Div", "Dq1hLxMGW"];

const variantClassNames = {Dq1hLxMGW: "framer-v-pk76x1", EvriYDBXZ: "framer-v-t8jrd3", hNcas0Div: "framer-v-ps2q26"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {desktop: "EvriYDBXZ", phone: "Dq1hLxMGW", tablet: "hNcas0Div"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "EvriYDBXZ"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "EvriYDBXZ", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-rsZD9", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-t8jrd3", className)} data-framer-name={"desktop"} layoutDependency={layoutDependency} layoutId={"EvriYDBXZ"} ref={ref} style={{...style}} {...addPropertyOverrides({Dq1hLxMGW: {"data-framer-name": "phone"}, hNcas0Div: {"data-framer-name": "tablet"}}, baseVariant, gestureVariant)}><SVG className={"framer-11tsba"} data-framer-name={"verified"} fill={"rgba(0,0,0,1)"} intrinsicHeight={46} intrinsicWidth={46} layoutDependency={layoutDependency} layoutId={"I5007:17761;0:15899"} svg={"<svg width=\"46\" height=\"46\" viewBox=\"0 0 46 46\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M23.1462 0C29.1952 0 34.8821 2.35564 39.1594 6.63285C43.4367 10.9102 45.7923 16.5972 45.7923 22.6462C45.7923 28.6952 43.4367 34.3821 39.1594 38.6594C34.8821 42.9367 29.1952 45.2923 23.1462 45.2923C17.0971 45.2923 11.4101 42.9367 7.13285 38.6594C2.85555 34.3821 0.5 28.6952 0.5 22.6462C0.5 16.5972 2.85555 10.9101 7.13285 6.63285C11.4101 2.35564 17.0971 0 23.1462 0ZM3.15385 22.6462C3.15385 33.67 12.1223 42.6385 23.1462 42.6385C34.17 42.6385 43.1385 33.67 43.1385 22.6462C43.1385 11.6223 34.17 2.65385 23.1462 2.65385C12.1223 2.65385 3.15385 11.6223 3.15385 22.6462Z\" fill=\"#201747\"/>\n<path d=\"M33.9648 15.3798C33.4467 14.8618 32.6065 14.8618 32.0884 15.3799L20.3708 27.0975L14.2027 20.9293C13.6846 20.4112 12.8444 20.4112 12.3262 20.9293C11.8079 21.4474 11.8079 22.2876 12.3262 22.8058L19.4325 29.9122C19.6916 30.1713 20.0312 30.3008 20.3708 30.3008C20.7103 30.3008 21.05 30.1712 21.309 29.9122L33.9648 17.2564C34.483 16.7383 34.483 15.8981 33.9648 15.3798Z\" fill=\"#201747\"/>\n</svg>\n"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-rsZD9 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-rsZD9 .framer-1dej7q7 { display: block; }", ".framer-rsZD9 .framer-t8jrd3 { height: 46px; overflow: hidden; position: relative; width: 46px; }", ".framer-rsZD9 .framer-11tsba { bottom: 0px; flex: none; left: 0px; position: absolute; right: 0px; top: 0px; }", ".framer-rsZD9.framer-v-ps2q26 .framer-t8jrd3 { height: 42px; width: 42px; }", ".framer-rsZD9.framer-v-pk76x1 .framer-t8jrd3 { height: 37px; width: 37px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 46
 * @framerIntrinsicWidth 46
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"hNcas0Div":{"layout":["fixed","fixed"]},"Dq1hLxMGW":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables false
 */
const FramerYLHjLT0gx: React.ComponentType<Props> = withCSS(Component, css, "framer-rsZD9") as typeof Component;
export default FramerYLHjLT0gx;

FramerYLHjLT0gx.displayName = "verified icon";

FramerYLHjLT0gx.defaultProps = {height: 46, width: 46};

addPropertyControls(FramerYLHjLT0gx, {variant: {options: ["EvriYDBXZ", "hNcas0Div", "Dq1hLxMGW"], optionTitles: ["desktop", "tablet", "phone"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerYLHjLT0gx, [])